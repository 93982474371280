import { ReactNode } from 'react';

import { AnimatedCheckmark } from 'components/Icon/AnimatedCheckmark';

import { SwitchButton, SwitchHolder } from './Switch.styled';

type Props = {
  ariaLabel?: string;
  ariaLabelledBy?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  id?: string;
  label?: string;
  labelPlacement?: 'left' | 'right';
  onChange?: (checked: boolean) => void;
  qaId?: string;
  value?: string;
  name?: string;
  tooltip?: ReactNode;
};

export function Switch({
  ariaLabel,
  ariaLabelledBy,
  defaultChecked,
  checked,
  disabled,
  hasError,
  id,
  label,
  labelPlacement,
  onChange,
  qaId,
  value,
  name,
  tooltip,
}: Props) {
  return (
    <SwitchHolder $hasError={hasError} $labelPlacement={labelPlacement}>
      <SwitchButton role="switch">
        <AnimatedCheckmark size={16} colorName="brandBlue" />
      </SwitchButton>
      <input
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        data-qa-id={qaId}
        defaultChecked={defaultChecked}
        value={value}
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={(e) => {
          e.stopPropagation();
          if (onChange) {
            onChange(e.target.checked);
          }
        }}
        disabled={disabled}
      />
      <span>{label}</span>
      {tooltip}
    </SwitchHolder>
  );
}
