import styled, { css } from 'styled-components';

import { colors, spacing, transition } from 'theme/theme';

export const SwitchHolder = styled.label<{
  $labelPlacement?: 'left' | 'right';
  $hasError?: boolean;
}>`
  align-items: flex-start;
  position: relative;
  display: inline-flex;
  gap: ${spacing[4]};

  flex-direction: ${(props) =>
    props.$labelPlacement === 'left' ? 'row-reverse' : 'row'};

  &:focus-visible {
    > [role='switch'] {
      outline: 1px auto -webkit-focus-ring-color;
    }
  }

  > span:last-child {
    transform: translateY(-2px);
  }

  input[type='checkbox'] {
    appearance: none;
    cursor: pointer;
    position: absolute;
    margin: 0;
    height: 100%;
    width: 100%;

    &::-ms-check {
      display: none;
    }

    &:disabled {
      cursor: auto;
    }
  }

  &:has(input:checked) > [role='switch'] {
    background: ${colors.brandBlue};

    > [role='img'] {
      opacity: 1;
      transform: translateX(16px) translateY(-50%);
    }
  }

  &:has(input:checked) > [role='switch']::before {
    transform: translateX(16px) translateY(-50%);
  }

  :has(input:disabled) [role='switch']::before {
    opacity: 0.75;
  }

  ${(props) =>
    props.$hasError &&
    css`
      border: solid 1px ${colors.alertRed};
      box-shadow: 0 0 0 1px ${colors.alertRed};
      padding-right: 15px;
      padding-left: 15px;
    `};
`;

export const SwitchButton = styled.span`
  background: ${colors.elementGrey};
  border-radius: 12px;
  flex-shrink: 0;
  height: 24px;
  opacity: 1;
  position: relative;
  transition: all ${transition};
  width: 40px;

  &::before {
    content: '';
    left: 2px;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transition: all ${transition};
    transform: translateX(0) translateY(-50%);
  }

  > [role='img'] {
    opacity: 0;
    position: absolute;
    left: 4px;
    top: 50%;
    transition: transform ${transition};
    transform: translateX(0) translateY(-50%);
  }
`;
